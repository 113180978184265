import './Lists.scss';
import { useEffect, useRef, useReducer } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as funcs from '../../functions';
import withReactContent from 'sweetalert2-react-content';

export default function Lists() {
    const premiumWatchlistRef = useRef(null);
    const categoryWatchlistRef = useRef(null);
    const msrpPricesRef = useRef(null);
    const blacklistRef = useRef(null)
    const footersRef = useRef(null)
    const associateTagRef = useRef(null)
    const swal = withReactContent(Swal)
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        axios({
            method: 'get',
            url: '/api/loadlists'
        }).then((msg) => {
            for (let i = 0; i < msg.data.premiumWatchlist.length; i++) {
                premiumWatchlistRef.current.value += msg.data.premiumWatchlist[i] + '\n';
            }

            for (let i = 0; i < msg.data.categoryWatchlist.length; i++) {
                categoryWatchlistRef.current.value += msg.data.categoryWatchlist[i] + '\n';
            }

            for (let i = 0; i < msg.data.msrpPrices.length; i++) {
                msrpPricesRef.current.value += msg.data.msrpPrices[i] + '\n';
            }

            for (let i = 0; i < msg.data.blacklist.length; i++) {
                blacklistRef.current.value += msg.data.blacklist[i] + '\n';
            }

            for (let i = 0; i < msg.data.footers.length; i++) {
                footersRef.current.value += msg.data.footers[i] + '\n';
            }

            for (let i = 0; i < msg.data.associateTag.length; i++) {
                associateTagRef.current.value += msg.data.associateTag[i] + '\n';
            }

            // Auto trim lists
            premiumWatchlistRef.current.value = premiumWatchlistRef.current.value.trim();
            categoryWatchlistRef.current.value = categoryWatchlistRef.current.value.trim();
            msrpPricesRef.current.value = msrpPricesRef.current.value.trim();
            blacklistRef.current.value = blacklistRef.current.value.trim();
            footersRef.current.value = footersRef.current.value.trim();
            associateTagRef.current.value = associateTagRef.current.value.trim();
            forceUpdate();
        })
    }, [])

    function saveLists() {
        swal.fire({
            title: <p>Update Global Lists?</p>,
            text: `Are you sure you want to update the lists on the database?`,
            showCancelButton: true,
            confirmButtonText: 'Update',
        }).then((result) => {
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: '/api/savelists',
                    data: {
                        premiumWatchlist: premiumWatchlistRef.current.value.replaceAll("\t", " ").replaceAll("  ", "").split('\n'),
                        categoryWatchlist: categoryWatchlistRef.current.value.replaceAll("\t", " ").replaceAll("  ", "").split('\n'),
                        msrpPrices: msrpPricesRef.current.value.replaceAll("\t", " ").replaceAll("  ", "").split('\n'),
                        blacklist: blacklistRef.current.value.replaceAll("\t", " ").replaceAll("  ", "").split('\n'),
                        footers: footersRef.current.value.replaceAll("\t", " ").replaceAll("  ", "").split('\n'),
                        associateTag: associateTagRef.current.value.replaceAll("\t", " ").replaceAll("  ", "").split('\n'),
                    }
                })
            }
        })
    }

    return (
        <div id='lists-div'>
            <div className='subtitle'>ASIN Watch List - {funcs.formatNumber(premiumWatchlistRef.current?.value?.split('\n')?.length)}:</div>
            <textarea type='textarea' className='textarea list' spellCheck={false} ref={premiumWatchlistRef} />

            <div className='subtitle'>Category Watch List - {funcs.formatNumber(categoryWatchlistRef.current?.value?.split('\n')?.length)}:</div>
            <textarea type='textarea' className='textarea list' spellCheck={false} ref={categoryWatchlistRef} />

            <div className='subtitle'>MSRP Prices - {funcs.formatNumber(msrpPricesRef.current?.value?.split('\n')?.length)}:</div>
            <textarea type='textarea' className='textarea list' spellCheck={false} ref={msrpPricesRef} />

            <div className='subtitle'>ASIN Blacklist - {funcs.formatNumber(blacklistRef.current?.value?.split('\n')?.length)}:</div>
            <textarea type='textarea' className='textarea list' spellCheck={false} ref={blacklistRef} />

            <div className='subtitle'>Footers - {funcs.formatNumber(footersRef.current?.value?.split('\n')?.length)}:</div>
            <textarea type='textarea' className='textarea list' spellCheck={false} ref={footersRef} />

            <div className='subtitle'>Associate Tag - {funcs.formatNumber(associateTagRef.current?.value?.split('\n')?.length)}:</div>
            <textarea type='textarea' className='textarea list' spellCheck={false} ref={associateTagRef} />

            <br></br>
            <br></br>

            <button
                id='save-watchlist'
                onClick={() => { saveLists() }}
                style={{ width: '135px', height: '30px', marginRight: '10px' }}
            >
                Save Global Lists
            </button>
        </div >
    );
}