import './Login.scss';
import React, { useRef } from 'react';

export default function Login(props) {
    const passwordRef = useRef(null);

    return (
        <div id='login-div'>
            <div>Password</div>
            <input type="password" id='password-input' ref={passwordRef} onKeyDown={(e) => {
                if (e.key === "Enter") {
                    props.authenticate(passwordRef.current.value);
                }
            }} />
            <br></br>
            <button id="enter-button" onClick={() => {
                props.authenticate(passwordRef.current.value);
            }}>Enter</button>
        </div>
    );
}