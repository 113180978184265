import Header from './comps/Header/Header';
import Overview from './comps/Overview/Overview';
import Login from './comps/Login/Login';
import Lists from './comps/Lists/Lists';
import Graph from './comps/Graph/Graph';
import './App.scss';
import axios from 'axios';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

var authed = false;

export default function App() {
    const [checkAuthed, setcheckAuthed] = useState(false)

    function authenticate(password) {
        axios({
            method: 'post',
            url: `/api/authenticate`,
            data: { password: password }
        }).then((msg) => {
            let response = msg.data;
            if (response === "Success") {
                authed = true
                setcheckAuthed(!checkAuthed)
            }
        })
    }

    return (
        <div id="content">
            <Header />
            <Router>
                <Routes>
                    <Route path="/overview" element={
                        <>
                            {!authed ?
                                <Login authenticate={authenticate} /> :
                                <>
                                    <Overview leaderboard={false} />
                                    <Graph />
                                    <Lists />
                                </>
                            }
                        </>
                    } />
                    <Route path="/leaderboard" element={
                        <>
                            <Overview leaderboard={true} />
                            <Graph />
                        </>
                    } />
                </Routes>
            </Router>
        </div>
    )
}