import './Overview.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import * as funcs from '../../functions';

export default function Overview(props) {
    const [machines, setMachines] = useState();
    const [stats, setStats] = useState({});
    const [overallStats, setOverallStats] = useState({});
    const [sortedMachines, setSortedMachines] = useState();

    function update() {
        //. Update machines
        axios({
            method: 'get',
            url: '/api/getMachines',
        }).then((msg) => {
            msg.data.sort((a, b) => a.Name.localeCompare(b.Name))
            setMachines(msg.data)
        })

        //. Update stats
        axios({
            method: 'get',
            url: '/api/getStats',
        }).then((msg) => {
            let overallStats = {
                Processed: 0,
                RateLimits: 0,
                Discounts: 0,
                asinPerSec: 0,
            }

            let updatedStats = {};

            for (const key of Object.keys(msg.data)) {
                let data = msg.data[key];
                overallStats.Processed += data.Processed
                overallStats.RateLimits += data.RateLimits
                overallStats.Discounts += data.Discounts
                overallStats.asinPerSec += data.AsinPerSec

                updatedStats[key] = data;
            }

            setStats(updatedStats)
            setOverallStats(overallStats)
        })

    }


    useEffect(() => {
        update()
        setInterval(() => {
            update()
        }, 2000);
    }, [])

    // Update the useEffect hook
    useEffect(() => {
        if (props.leaderboard && machines && stats) {
            const sorted = [...machines].sort((a, b) => {
                const aStats = stats[a.Name];
                const bStats = stats[b.Name];
                const aAsinsPerSecond = aStats ? aStats.AsinPerSec : 0;
                const bAsinsPerSecond = bStats ? bStats.AsinPerSec : 0;

                // If aStats or bStats is false or does not exist
                if (!aStats || !bStats) {
                    if (!aStats && !bStats) return 0;
                    return aStats ? -1 : 1;
                }

                // If aAsinsPerSecond or bAsinsPerSecond is 0
                if (aAsinsPerSecond === 0 || bAsinsPerSecond === 0) {
                    if (aAsinsPerSecond === bAsinsPerSecond) return 0;
                    return aAsinsPerSecond ? -1 : 1;
                }

                return bAsinsPerSecond - aAsinsPerSecond;
            });
            setSortedMachines(sorted);
        } else {
            setSortedMachines(machines);
        }
    }, [machines, stats, props.leaderboard]);

    function commandBot(name, type, data) {
        axios({
            method: 'post',
            url: '/api/commandBot',
            data: {
                Name: name,
                Type: type,
                Data: data
            }
        })
    }

    //. Overview Table
    return (
        <div id='overview-div'>
            <table style={{ width: '100%', paddingTop: '20px', cursor: 'default', fontSize: "13px" }}>
                <thead>
                    <tr>
                        {/* Header */}
                        <td style={{ width: '100px', fontWeight: 'bold' }}>Name</td>
                        <td style={{ width: '100px', fontWeight: 'bold' }}>Discord</td>
                        <td style={{ width: '75px', fontWeight: 'bold' }}>Status</td>
                        <td style={{ width: '100px', fontWeight: 'bold' }}>Runtime</td>
                        {!props.leaderboard &&
                            <td style={{ width: '75px', fontWeight: 'bold' }}>State</td>
                        }
                        <td style={{ width: '75px', fontWeight: 'bold' }}>CPU</td>
                        <td style={{ width: '75px', fontWeight: 'bold' }}>RAM</td>
                        <td style={{ width: '75px', fontWeight: 'bold' }}>Active</td>
                        <td style={{ width: '100px', fontWeight: 'bold' }}>Processed</td>
                        <td style={{ width: '125px', fontWeight: 'bold' }}>Rate Limit</td>
                        <td style={{ width: '100px', fontWeight: 'bold' }}>Discount</td>
                        <td style={{ width: '90px', fontWeight: 'bold' }}>ASIN/Sec</td>
                        <td style={{ width: '100px', fontWeight: 'bold' }}>Avg Time</td>
                        <td style={{ width: '85px', fontWeight: 'bold' }}>Unq ASIN</td>
                        <td style={{ width: '70px', fontWeight: 'bold' }}>Version</td>
                        {!props.leaderboard &&
                            <td style={{ width: 'auto', fontWeight: 'bold' }}></td>
                        }
                    </tr>
                </thead>
                <tbody>
                    {sortedMachines?.map((machine, i) => {
                        if (!Object.keys(stats).includes(machine.Name)) {
                            return (
                                <tr key={i}>
                                    {/* --- Machine is OFFLINE --- */}
                                    <td>{machine.Name}</td>
                                    <td className='note-column'>{machine.DiscordName}</td>
                                    <td><span style={{ color: '#f95959' }}>Offline</span></td>
                                    <td>-</td>
                                    {!props.leaderboard &&
                                        <td>-</td>
                                    }
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    {!props.leaderboard &&
                                        <td></td>
                                    }
                                </tr>
                            )
                        } else if (Object.keys(stats).includes(machine.Name) && !stats[machine.Name].Status) {
                            return (
                                <tr key={i}>
                                    {/* --- Machine is INACTIVE --- */}
                                    <td>{machine.Name}</td>
                                    <td className='note-column'>{machine.DiscordName}</td>
                                    <td><span style={{ color: '#fbff00' }}>Inactive</span></td>
                                    <td>-</td>
                                    {!props.leaderboard &&
                                        <td>-</td>
                                    }
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    {!props.leaderboard &&
                                        <td>
                                            <button onClick={() => { commandBot(machine.Name, "Toggle", "") }}>Start {machine.Name}</button>
                                        </td>
                                    }
                                </tr>
                            )
                        } else {
                            return (
                                <tr key={i}>
                                    {/* --- Machine is RUNNING ---*/}
                                    <td> {machine.Name}</td>
                                    <td className='note-column'>{machine.DiscordName}</td>
                                    <td>
                                        {/* Status */}
                                        <span style={{ color: '#4dff63' }}>Running</span>
                                    </td>
                                    <td>
                                        {/* Runtime */}
                                        {Object.keys(stats).includes(machine.Name) ? funcs.msToTime(Date.now() - stats[machine.Name].StartTime) : 0}
                                    </td>
                                    {!props.leaderboard &&
                                        <td>
                                            {/* Region */}
                                            {Object.keys(stats).includes(machine.Name) && stats[machine.Name].Region}
                                        </td>
                                    }
                                    <td>
                                        {/* CPU */}
                                        {Object.keys(stats).includes(machine.Name) && stats[machine.Name].CpuUsage.toFixed(0) + "%"}
                                    </td>
                                    <td>
                                        {/* Mem */}
                                        {Object.keys(stats).includes(machine.Name) && stats[machine.Name].MemUsage + "%"}
                                    </td>
                                    <td>
                                        {/* Active Instances */}
                                        {Object.keys(stats).includes(machine.Name) ? funcs.formatNumber(stats[machine.Name].ActiveInstances) : 0}
                                    </td>
                                    <td>
                                        {/* Processed */}
                                        {Object.keys(stats).includes(machine.Name) ? funcs.formatNumber(stats[machine.Name].Processed) : 0}
                                    </td>
                                    <td>
                                        {/* Rate limits */}
                                        <span style={{ color: funcs.colorize((stats[machine.Name].RateLimits / stats[machine.Name].Processed * 100) / 125 * 100, 'reverse') }}>
                                            {Object.keys(stats).includes(machine.Name) ? stats[machine.Name].Processed === 0 ? '0' : funcs.formatNumber(stats[machine.Name].RateLimits) + ` (${(stats[machine.Name].RateLimits / stats[machine.Name].Processed * 100).toFixed(0)}%)` : 0}
                                        </span>
                                    </td>
                                    <td>
                                        {/* Discount Failures */}
                                        {Object.keys(stats).includes(machine.Name) ? funcs.formatNumber(stats[machine.Name].Discounts) : 0}
                                    </td>
                                    <td>
                                        {/* Asins per second */}
                                        <span style={{ color: funcs.colorize((stats[machine.Name].AsinPerSec / 40 * 100)) }}>
                                            {Object.keys(stats).includes(machine.Name) ? stats[machine.Name].AsinPerSec.toFixed(2) : 0}
                                        </span>
                                    </td>
                                    <td>
                                        {/* Average processing time */}
                                        {Object.keys(stats).includes(machine.Name) ? stats[machine.Name].AvgTime.toFixed(2) + " sec" : 0}
                                    </td>
                                    <td>
                                        {/* Unique Asin */}
                                        {Object.keys(stats).includes(machine.Name) ? stats[machine.Name].UniqueAsins : 0}
                                    </td>
                                    <td>
                                        {/* Version */}
                                        {stats[machine.Name].Version}
                                    </td>
                                    {!props.leaderboard &&
                                        <td>
                                            {/* Toggle bot button */}
                                            <button onClick={() => { commandBot(machine.Name, "Toggle", "") }}>Stop {machine.Name}</button>
                                        </td>
                                    }
                                </tr>
                            )
                        }
                    })}
                    {Object.keys(stats).length > 0 &&
                        <tr>
                            <td>Overall</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            {!props.leaderboard &&
                                <td>-</td>
                            }
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                                {/* Processed */}
                                {funcs.formatNumber(overallStats.Processed)}
                            </td>
                            <td>
                                {/* Rate limits */}
                                <span style={{ color: funcs.colorize((overallStats.RateLimits / overallStats.Processed * 100) / 125 * 100, 'reverse') }}>
                                    {overallStats.Processed === 0 ? '0' : funcs.formatNumber(overallStats.RateLimits) + ` (${(overallStats.RateLimits / overallStats.Processed * 100).toFixed(0)}%)`}
                                </span>
                            </td>

                            <td>
                                {/* Discount Failures */}
                                {funcs.formatNumber(overallStats.Discounts)}
                            </td>

                            <td>
                                {/* Asins per second */}
                                {overallStats.asinPerSec?.toFixed(2)}
                            </td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            {!props.leaderboard &&
                                <td></td>
                            }
                        </tr>
                    }
                </tbody>
            </table>
            <br></br>
            {!props.leaderboard &&
                <>
                    <a target="_blank" rel="noreferrer" href="https://skydrive.digi-safe.co/files/Reignforest/foreman-log.txt"><button>Foreman Log</button></a>
                    <span style={{ width: '5px' }}> </span>
                    <a target="_blank" rel="noreferrer" href="https://skydrive.digi-safe.co/files/Reignforest/sawmill-log.txt"><button>Sawmill Log</button></a>
                    <span style={{ width: '5px' }}> </span>
                    <a target="_blank" rel="noreferrer" href="https://skydrive.digi-safe.co/contents?dir=Reignforest/Screenshots"><button>Screenshots</button></a>
                    <span style={{ width: '5px' }}> </span>
                    <button onClick={() => {
                        commandBot("", "Kill", "")
                    }}>Killswitch</button>
                </>
            }
        </div >
    );
}