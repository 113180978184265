import clipboard from 'clipboardy';
const { DateTime } = require("luxon");


export function convertDateTime(date) {
    return DateTime.fromISO(date).setLocale(navigator.language).toLocaleString(DateTime.DATETIME_SHORT).replace(",", "");
}

export function convertDateTimeWithSeconds(date) {
    return DateTime.fromISO(date).setLocale(navigator.language).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS).replace(",", "");
}

export function timeSince(date) {
    return DateTime.fromISO(date).toRelative({ locale: 'en', padding: 1000 });
}

export function timeSinceRelative(date) {
    return DateTime.fromISO(date).toRelativeCalendar({ locale: 'en' });
}

export function convertTime(time) {
    return DateTime.fromISO(time).setLocale(navigator.language).toLocaleString(DateTime.TIME_SIMPLE).replace(",", "");
}

export function convertTimeWithSeconds(time) {
    return DateTime.fromISO(time).setLocale(navigator.language).toLocaleString(DateTime.TIME_WITH_SECONDS).replace(",", "");
}

export function convertDate(date) {
    return DateTime.fromISO(date).setLocale(navigator.language).toLocaleString(DateTime.DATE_SHORT).replace(",", "");
}

export function duration(date) {
    return DateTime.now().diff(DateTime.fromISO(date).setLocale(navigator.language), ['days', 'hours']).toObject();
}

export function timeDiff(start, stop) {
    const date1 = DateTime.fromISO(stop)
    const date2 = DateTime.fromISO(start)

    const diff = date1.diff(date2, ["years", "months", "days", "hours"])

    return diff.toObject()
}

// Returns a string with the first letter capitalized
export function capitalizeFirstLetter(string) {
    try {
        string = String(string);
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch { }
}

export function getCountryName(countryCode) {
    try {
        let regionNames = new Intl.DisplayNames(['en'], {
            type: 'region'
        });
        return regionNames.of(countryCode);
    } catch {
        return "Unkown Country"
    }
}

export function copyToClipboard(text, e, changeColor = true) {
    e.persist();
    if (e.target.innerHTML !== "Copied!") {
        clipboard.write(text);
        let originalColor = e.target.style.color;
        let originalText = e.target.innerHTML;
        if (changeColor === true) {
            e.target.style.color = '#40ff29';
        }
        e.target.innerHTML = "Copied!"
        setTimeout(() => {
            e.target.innerHTML = originalText;
            e.target.style.color = originalColor;
        }, 2000);
    }
}

// Adds commas to numbers that are missing them
export function formatNumber(x) {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return 0;
    }
}

// Converts ratio to color
export function colorize(perc, direction = 'forward') {
    var r, g, b = 0;

    if (perc > 100) {
        perc = 100;
    }

    if (direction === 'forward') {
        if (perc < 50) {
            r = 255;
            g = Math.round(5.1 * perc);
        }
        else {
            g = 255;
            r = Math.round(510 - 5.10 * perc);
        }
    } else if (direction === 'reverse') {
        if (perc <= 50) {
            g = 255;
            r = Math.round(5.1 * perc);
        }
        else {
            r = 255;
            g = Math.round(255 - 5.10 * perc);
        }
    }

    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}

// Converts seconds to hours (rounded)
export function secondsToHours(timeInSeconds) {
    return parseInt(Math.floor(timeInSeconds / 3600));
}

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? formatNumber(h) + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
}

// Set cookie
export function setCookie(c_name, value, expiredays) {
    var exdate = new Date()
    exdate.setDate(exdate.getDate() + expiredays)
    document.cookie = c_name + "=" + encodeURI(value) + ((expiredays == null) ? "" : ";expires=" + exdate + ";path=/")
}

// Get cookie by name
export function getCookie(c_name) {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start !== -1) {
            c_start = c_start + c_name.length + 1;
            var c_end = document.cookie.indexOf(";", c_start);
            if (c_end === -1) {
                c_end = document.cookie.length
            }
            return decodeURI(document.cookie.substring(c_start, c_end))
        }
    }
    return null;
}

export function deleteCookie(name) {
    document.cookie = name + '=; Max-Age=0'
}

export function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}

export function focusWindow(e) {
    var selectedWindow;
    if (!e.target.classList.contains('modal')) {
        selectedWindow = e.target.closest(".modal")
    } else {
        selectedWindow = e.target;
    }

    for (let i = 0; i < document.getElementsByClassName('modal').length; i++) {
        const element = document.getElementsByClassName('modal')[i];
        element.style.zIndex = 3
    }

    selectedWindow.style.zIndex = 50;
}

export function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
        days = Math.floor(duration / (1000 * 60 * 60 * 24));

    var result = "";

    if (days > 0) {
        result += days + "d ";
    }
    
    if (hours > 0 || days > 0) {
        result += hours + "h ";
    }
    
    if (minutes > 0 || hours > 0 || days > 0) {
        result += minutes + "m ";
    }

    result += seconds + "s";

    return result;
}

