import './Graph.scss';
import { useState, useRef } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import * as funcs from '../../functions';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
);

export default function Graph() {
    const [labels, setLabels] = useState([]);
    const [prices, setPrices] = useState([]);
    const [title, setTitle] = useState("");
    const [retailPrice, setRetailPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const searchRef = useRef(null);
    const conditionRef = useRef(null);

    function search() {
        setLoading(true)
        axios({
            method: 'post',
            url: '/api/getTrend',
            data: { asin: searchRef.current.value }
        }).then((msg) => {
            let results = msg.data
            let prices = []
            let labels = []
            results.forEach(result => {
                if (result.inspection === conditionRef.current.value || conditionRef.current.value === "Any Condition") {
                    setTitle(result.title)
                    setRetailPrice(result.retailPrice)
                    prices.push(result.price)
                    labels.push(funcs.convertDate(result.startTime))
                }
            });
            setLabels(labels)
            setPrices(prices)
            setLoading(false)
        })
    }

    return (
        <div id='graph-div'>
            <div id='graph-div'>

                <div style={{ display: "flex", alignItems: 'center' }}>
                    <input type="text" className='input' ref={searchRef} placeholder={"ASIN"} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            search()
                        }
                    }} />

                    <select className='input' ref={conditionRef}>
                        <option>Any Condition</option>
                        <option>Used - Like New</option>
                        <option>Used - Very Good</option>
                        <option>Used - Good</option>
                        <option>Used - Acceptable</option>
                    </select>

                    <button onClick={() => { search() }}>Search</button>

                    <span style={{ paddingLeft: '10px', display: loading ? "block" : "none" }}>Loading...</span>

                    <span style={{ paddingLeft: '10px', display: loading ? "none" : "block" }}>{`${title} - $${retailPrice}`}</span>
                </div>


                <div id='graph-container'>
                    <Line
                        data={{
                            labels: labels,
                            datasets: [{
                                label: 'Historical Prices',
                                data: prices,
                                backgroundColor: "white",
                                borderColor: "white",
                                borderWidth: 2,
                            }]
                        }}
                        options={
                            {
                                elements: {
                                    line: {
                                        tension: 0.3
                                    },
                                    point: {
                                        radius: 2
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            color: '#333333',
                                            borderColor: '#919191'
                                        },
                                        ticks: {
                                            color: '#ffffff'
                                        }
                                    },
                                    y: {
                                        beginAtZero: true,
                                        min: 0,

                                        grid: {
                                            color: '#333333',
                                            borderColor: '#919191'
                                        },
                                        ticks: {
                                            color: '#ffffff'
                                        }
                                    },
                                },
                                animation: {
                                    duration: 300
                                },
                                //animation: false,
                                plugins: {
                                    legend: {
                                        labels: {
                                            color: 'white',
                                            font: {
                                                size: 14,
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    />
                </div>
            </div>
        </div >
    );
}